import { PriceInterface } from "@constants/interfaces";
import { FeeManager } from "@utils/Discount/FeeManager";
import { DiscountCode } from "@utils/Discount/DiscountCode";

export interface DiscountManager {
  get feeManager(): FeeManager;

  get priceLevel(): number;

  getDiscount(price: PriceInterface, partnerPrice?: PriceInterface): PriceInterface;

  getDiscountPercentage(price: PriceInterface, partnerPrice?: PriceInterface): number;
}

export class DiscountManagerImpl implements DiscountManager {
  private readonly _feeManager: FeeManager;
  private _discountCode: DiscountCode;
  private readonly onDataChange?: () => void;

  constructor(discountCode: DiscountCode, feeManager: FeeManager, onDataChange?: () => void) {
    this._discountCode = discountCode;
    this._feeManager = feeManager;
    this.onDataChange = onDataChange;
  }

  get feeManager(): FeeManager {
    return this._feeManager;
  }

  get priceLevel(): number {
    return this._discountCode?.priceLevel || 0;
  }

  addSurcharge(price: PriceInterface) {
    return {
      ...price,
      price: price.price + this._feeManager.getSurcharge(price).price
    };
  }

  addDiscount(price: PriceInterface) {
    return {
      ...price,
      price: price.price * (1 - this._discountCode.discountPercentage / 100)
    };
  }

  getDiscount(price: PriceInterface, partnerPrice?: PriceInterface): PriceInterface {
    if (!partnerPrice) {
      const priceWithDiscount = this.addDiscount(price);
      return {
        level: this.priceLevel,
        price: price.price - priceWithDiscount.price,
        currency: priceWithDiscount.currency
      };
    }

    // If partner price is available, then the discount is calculated from the partner price
    const partnerPriceWithSurcharge = this.addSurcharge(partnerPrice);

    return {
      level: this.priceLevel,
      price: price.price - partnerPriceWithSurcharge.price,
      currency: partnerPriceWithSurcharge.currency
    };
  }

  getDiscountPercentage(price: PriceInterface, partnerPrice?: PriceInterface): number {
    // How large is the discount in percentage
    const discount = this.getDiscount(price, partnerPrice);

    return Math.round((discount.price / price.price) * 100);
  }
}
