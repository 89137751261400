import { Address, AddressJSONObject } from "@utils/Address/Address";

import { Customer } from "@utils/Customer/Customer";
import { Cart, CartJSONObject } from "@utils/Cart/Cart";
import { CreateOrderData, OrderData, OrderRepository } from "@utils/Order/OrderRepository";
import { OrderItemRepository } from "@utils/Order/OrderItemRepository";
import { EmailHandler, ResponseStatus } from "@utils/email/EmailHandler";
import { User, UserJSONObject } from "@utils/User/User";
import { LocaleCode } from "@utils/Country/countryEnums";
import { AddressFactory } from "@utils/Address/AddressFactory";

import { Retailer, RetailerImpl, RetailerJSONObject } from "@utils/Retailer/Retailer";
import { makeAutoObservable } from "mobx";
import { uuid4 } from "@sentry/utils";

export interface OrderJSONObject {
  id?: number;
  uuid?: string;
  cart: CartJSONObject;
  user?: UserJSONObject;
  type: OrderType;
  status: OrderStatus;
  reference: string;
  message: string;
  contactPerson: string;
  company: string;
  retailer?: RetailerJSONObject;
  useInvoiceAsDeliveryAddress: boolean;
  invoiceAddress?: AddressJSONObject;
  deliveryAddress?: AddressJSONObject;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum OrderStatus {
  cart = "cart",
  checkout = "checkout",
  received = "received",
  sent = "sent"
}

export enum OrderType {
  enquiry = "enquiry",
  order = "order"
}

export interface OrderInformation {
  type: OrderType;
  status: OrderStatus;
  reference: string;
  contactPerson: string;
  message: string;
  company: string;
  invoiceAddress?: Address;
  deliveryAddress?: Address;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Order extends OrderInformation {
  id?: number;
  locale: LocaleCode;
  cart: Cart;
  user: User | null;
  customer?: Customer;
  retailer?: Retailer;
  useInvoiceAsDeliveryAddress: boolean;

  submit(): Promise<number | undefined>;

  save(): Promise<OrderData | null>;

  delete(): Promise<boolean>;

  clear(): void;

  saveState(): void;

  restoreState(): void;

  sendConfirmationEmail(): Promise<boolean>;

  toCreateOrderData(): CreateOrderData;

  toJSON(): OrderJSONObject;
}

export class OrderImpl implements Order {
  private readonly orderRepository: OrderRepository;
  private readonly orderItemRepository: OrderItemRepository;
  private readonly emailHandler: EmailHandler;
  public id?: number;
  public uuid?: string;
  public locale: LocaleCode;
  public cart: Cart;
  public user: User | null;
  public customer?: Customer;
  private _retailer?: Retailer;
  public type: OrderType;
  public status: OrderStatus;
  private _reference: string;
  private _message: string;
  private _contactPerson: string;
  public company: string;
  public createdAt?: Date;
  public updatedAt?: Date;
  private _invoiceAddress?: Address;
  private _deliveryAddress?: Address;
  private _useInvoiceAsDeliveryAddress: boolean;
  private readonly onDataChange?: () => void;

  constructor({
    id,

    locale,
    cart,
    user,
    customer,
    retailer,
    type,
    status,
    reference,
    message,
    contactPerson,
    company,
    createdAt,
    updatedAt,
    onChange,
    orderRepository,
    orderItemRepository,
    emailHandler
  }: {
    id?: number;
    locale: LocaleCode;
    cart: Cart;
    user: User | null;
    customer?: Customer;
    retailer?: Retailer;
    type?: OrderType;
    status?: OrderStatus;
    reference?: string;
    message?: string;
    contactPerson?: string;
    company?: string;
    createdAt?: Date;
    updatedAt?: Date;
    onChange?: () => void;
    orderRepository: OrderRepository;
    orderItemRepository: OrderItemRepository;
    emailHandler: EmailHandler;
  }) {
    this._useInvoiceAsDeliveryAddress = true;

    this.id = id;
    this.uuid = this.uuid ? this.uuid : uuid4();
    this.locale = locale;
    this.cart = cart;
    this.user = user || null;
    this.customer = customer;
    this._retailer = retailer;
    this.type = this.user ? OrderType.order : type || OrderType.enquiry;
    this.status = status || OrderStatus.cart;
    this._reference = reference || "";
    this._message = message || "";
    this._contactPerson = contactPerson || "";
    this.company = company || "";
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.onDataChange = onChange;
    this.orderRepository = orderRepository;
    this.orderItemRepository = orderItemRepository;
    this.emailHandler = emailHandler;

    makeAutoObservable(this);
  }

  get reference(): string {
    return this._reference;
  }

  set reference(reference: string) {
    this._reference = reference;
    this.onDataChange?.();
    this.saveState();
  }

  get message(): string {
    return this._message;
  }

  set message(message: string) {
    this._message = message;
    this.onDataChange?.();
    this.saveState();
  }

  get retailer(): Retailer | undefined {
    return this._retailer;
  }

  set retailer(newRetailer: Retailer | undefined) {
    this._retailer = newRetailer;
    this.onDataChange?.();
    this.saveState();
  }

  get contactPerson(): string {
    return this._contactPerson;
  }

  set contactPerson(contactPerson: string) {
    this._contactPerson = contactPerson;
    this.onDataChange?.();
    this.saveState();
  }

  get invoiceAddress(): Address | undefined {
    return this._invoiceAddress;
  }

  set invoiceAddress(address: Address | undefined) {
    this._invoiceAddress = address;
    this.onDataChange?.();

    if (this.invoiceAddress) {
      this.invoiceAddress.onDataChange = this.onDataChange;
    }
    this.saveState();
  }

  get deliveryAddress(): Address | undefined {
    if (this._useInvoiceAsDeliveryAddress) {
      return this.invoiceAddress;
    }

    return this._deliveryAddress;
  }

  set deliveryAddress(address: Address | undefined) {
    this._deliveryAddress = address;
    this.onDataChange?.();

    if (this.deliveryAddress) {
      this.deliveryAddress.onDataChange = this.onDataChange;
    }
    this.saveState();
  }

  get useInvoiceAsDeliveryAddress(): boolean {
    return this._useInvoiceAsDeliveryAddress;
  }

  set useInvoiceAsDeliveryAddress(useInvoiceAsDeliveryAddress: boolean) {
    this._useInvoiceAsDeliveryAddress = useInvoiceAsDeliveryAddress;
    if (useInvoiceAsDeliveryAddress) {
      this.deliveryAddress = this._invoiceAddress ? this._invoiceAddress : undefined;
    }
    this.onDataChange?.();
    this.saveState();
  }

  async submit(): Promise<number | undefined> {
    if (!this.invoiceAddress || !this.deliveryAddress) return;

    const invoiceAddress = await this.invoiceAddress.save();
    if (!invoiceAddress) return;

    if (this.invoiceAddress.id !== this.deliveryAddress.id) {
      const deliveryAddress = await this.deliveryAddress.save();
      if (!deliveryAddress) return;
    }

    this.status = OrderStatus.received;
    const order = await this.save();
    if (!order) {
      this.uuid = uuid4();
      return undefined;
    }

    this.id = order.id;

    const { status: externalStatus } = await this.emailHandler.sendOrderReceivedExternal({
      data: this.toJSON(),
      locale: this.locale,
      toEmail: this.invoiceAddress.email || this.user?.email
    });
    const { status: internalStatus } = await this.emailHandler.sendOrderReceivedInternal({
      data: this.toJSON(),
      locale: this.locale
    });

    if (externalStatus === ResponseStatus.Error || internalStatus === ResponseStatus.Error) {
      return (await this.delete()) ? undefined : order.id;
    }

    this.clear();

    return externalStatus === ResponseStatus.Success && internalStatus === ResponseStatus.Success
      ? order.id
      : undefined;
  }

  async save(): Promise<OrderData | null> {
    const { status, data } = await this.orderRepository.create(this.toCreateOrderData());
    if (!data || status === ResponseStatus.Error) {
      return null;
    }

    if (!data.order_items) {
      data.order_items = [];
    }

    for (const cartItem of this.cart.cartItems) {
      const orderItem = await this.orderItemRepository.create(data.id, cartItem);
      if (!orderItem) {
        return null;
      }

      cartItem.id = orderItem.id;
      data.order_items = [...data.order_items, orderItem];
    }

    return data;
  }

  async delete(): Promise<boolean> {
    // console.log("this.id : ", this.id);
    if (!this.id) return false;
    let result = false;

    for (const cartItem of this.cart.cartItems) {
      if (!cartItem.id) continue;
      result = await this.orderItemRepository.delete(this.id, cartItem.id);
    }

    result = await this.orderRepository.delete(this.id);
    return result;
  }

  clear() {
    this.uuid = uuid4();
    this._reference = "";
    this._message = "";
    this._contactPerson = "";
    this.company = "";
    this._invoiceAddress = undefined;
    this._deliveryAddress = undefined;
    this._useInvoiceAsDeliveryAddress = true;
    this._retailer = undefined;
    this.status = OrderStatus.cart;
    this.saveState();
  }

  saveState(): void {
    const state = {
      id: this.id,
      uuid: this.uuid,
      type: this.type,
      status: this.status,
      reference: this.reference,
      message: this.message,
      contactPerson: this._contactPerson,
      company: this.company,
      useInvoiceAsDeliveryAddress: this._useInvoiceAsDeliveryAddress,
      invoiceAddress: this.invoiceAddress?.toJSON(),
      deliveryAddress: this.deliveryAddress?.toJSON(),
      retailer: this.retailer?.toJSON()
    };

    localStorage.setItem("orderState", JSON.stringify(state));
  }

  restoreState(): void {
    const storedState = localStorage.getItem("orderState");
    if (storedState) {
      const state = JSON.parse(storedState) as {
        id?: number;
        uuid?: string;
        type?: OrderType;
        status?: OrderStatus;
        reference?: string;
        message?: string;
        contactPerson?: string;
        company?: string;
        useInvoiceAsDeliveryAddress?: boolean;
        invoiceAddress?: AddressJSONObject;
        deliveryAddress?: AddressJSONObject;
        retailer?: RetailerJSONObject;
      };

      if (state.id) {
        this.id = state.id;
      }
      if (state.uuid) {
        this.uuid = state.uuid;
      }
      if (state.type) {
        this.type = state.type;
      }
      if (state.status) {
        this.status = state.status;
      }
      if (state.reference) {
        this._reference = state.reference;
      }
      if (state.message) {
        this._message = state.message;
      }
      if (state.contactPerson) {
        this._contactPerson = state.contactPerson;
      }
      if (state.company) {
        this.company = state.company;
      }
      if (state.useInvoiceAsDeliveryAddress) {
        this._useInvoiceAsDeliveryAddress = state.useInvoiceAsDeliveryAddress;
      }
      if (state.invoiceAddress) {
        this._invoiceAddress = AddressFactory.createAddress(state.invoiceAddress);
        this._invoiceAddress.onDataChange = this.onDataChange;
      }
      if (state.deliveryAddress) {
        this._deliveryAddress = AddressFactory.createAddress(state.deliveryAddress);
        this._deliveryAddress.onDataChange = this.onDataChange;
      }
      if (state.retailer) {
        this._retailer = new RetailerImpl(state.retailer);
      }
    }
  }

  async sendConfirmationEmail(): Promise<boolean> {
    // const result = await this.emailHandler.sendOrderReceived(this);
    return true;
  }

  toCreateOrderData(): CreateOrderData {
    return {
      uuid: this.uuid,
      company: this.invoiceAddress?.company,
      user: this.user?.id,
      message: this.message,
      reference: this.reference,
      contactPerson: this._contactPerson,
      status: this.status,
      vat: this.cart.discountCode ? this.cart.vatWithDiscount : this.cart.vat,
      subTotal: this.cart.discountCode ? this.cart.subtotalWithDiscount : this.cart.subtotal,
      total: this.cart.discountCode ? this.cart.totalWithDiscount : this.cart.total,
      discount: this.cart.discountCode && this.cart.discount,
      fee: this.cart.discountCode && this.cart.fee,
      kickback: this.cart.discountCode && this.cart.kickback,
      surcharge: this.cart.discountCode && this.cart.discountCode?.surchargePercentage,
      retailer: this.cart.discountCode?.retailer?.id ? this.cart.discountCode?.retailer.id : this.retailer?.id,
      type: this.type,
      deliveryAddress: this.deliveryAddress?.id,
      invoiceAddress: this.invoiceAddress?.id
    };
  }

  toJSON(): OrderJSONObject {
    return {
      id: this.id,
      uuid: this.uuid,
      cart: this.cart.toJSON(),
      user: this.user?.toJSON(),
      type: this.type,
      status: this.status,
      reference: this.reference,
      message: this.message,
      contactPerson: this._contactPerson,
      company: this.company,
      retailer: this.retailer?.toJSON(),
      useInvoiceAsDeliveryAddress: this._useInvoiceAsDeliveryAddress,
      invoiceAddress: this.invoiceAddress?.toJSON(),
      deliveryAddress: this.deliveryAddress?.toJSON(),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    };
  }
}
