import { DiscountCode } from "@utils/Discount/DiscountCode";
import { PriceInterface } from "@constants/interfaces";

export interface FeeManager {
  getSurcharge(price: PriceInterface): PriceInterface;

  getFee(price: PriceInterface): PriceInterface;

  getKickback(price: PriceInterface): PriceInterface;
}

export class FeeManagerImpl implements FeeManager {
  private readonly _discountCode: DiscountCode;

  constructor(discountCode: DiscountCode) {
    this._discountCode = discountCode;
  }

  getSurcharge(price: PriceInterface): PriceInterface {
    return {
      ...price,
      price: price.price * (this._discountCode.surchargePercentage / 100)
    };
  }

  getFee(price: PriceInterface): PriceInterface {
    // Fee is the price difference between the total price and the total price with the fee price level
    const discountPriceWithSurcharge = price.price + this.getSurcharge(price).price;
    const feeDifference = discountPriceWithSurcharge - price.price;
    const fee = feeDifference * (this._discountCode.feePercentage / 100);

    return {
      level: price.level,
      price: fee,
      currency: price.currency
    };
  }

  getKickback(price: PriceInterface): PriceInterface {
    // Kickback is the price difference between the total price with the fee price level,
    // and the total price with the discount price level
    const discountPriceWithSurcharge = price.price + this.getSurcharge(price).price;
    const feeDifference = discountPriceWithSurcharge - price.price;
    const fee = feeDifference * (this._discountCode.feePercentage / 100);
    const kickback = feeDifference - fee;

    return {
      level: price.level,
      price: kickback,
      currency: price.currency
    };
  }
}
