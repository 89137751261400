"use client";

import { LocaleCode } from "@utils/Country/countryEnums";
import { useParams } from "next/navigation";

export const getClientLocale = (): LocaleCode => {
  const params = useParams();
  const locale = params?.lang;

  return locale && !Array.isArray(locale) ? (locale as LocaleCode) : LocaleCode.UnitedKingdom;
};
