import { ConfigurationInterface, PriceInterface } from "@constants/interfaces";
import { CurrencyCode, LocaleCode } from "@utils/Country/countryEnums";
import { DiscountCode, DiscountCodeInfo } from "@utils/Discount/DiscountCode";

import { ProductData } from "@utils/Product/interfaces";

export interface CartItemJSONObject {
  id?: number;
  price: PriceInterface | undefined;
  discountedPrice?: PriceInterface | undefined;
  total: PriceInterface | undefined;
  discountedTotal?: PriceInterface | undefined;
  priceLevel?: number;
  quantity: number;
  product?: {
    id: number;
    productNumber: string;
    elNumber: string;
    productName: string;
    productNameShort: string;
    price?: PriceInterface[];
  };
  configuration?: SimpleConfiguration;
  discountCode?: DiscountCodeInfo | null;
}

export interface SimpleStep {
  elNumber: string;
  stepId: number;
  productId: number;
  productNumber: string;
  productName: string;
  productCategory: string;
  price?: PriceInterface;
  discountedPrice?: PriceInterface;
  total?: PriceInterface;
  discountedTotal?: PriceInterface;
  optionId?: number;
  enableColumnPricing: boolean;
}

export interface SimpleConfiguration {
  name: string;
  locale: LocaleCode;
  configTypeId: number;
  configLayoutId: number;
  qualityTypeId: number;
  qualityImage?: string;
  selections: SimpleStep[];
}

export interface CartItem {
  get id(): number | undefined;

  get priceLevel(): number | undefined;

  get canShowPrices(): boolean;

  get quantity(): number;

  get configuration(): ConfigurationInterface | undefined;

  get product(): ProductData | undefined;

  get discountCode(): DiscountCode | undefined | null;

  set id(id: number | undefined);

  applyDiscountCode(discountCode: DiscountCode): void;

  disableDiscountCode(): void;

  getPrice(priceLevel?: number): PriceInterface | undefined;

  getDiscountedPrice(priceLevel?: number): PriceInterface | undefined;

  getTotal(priceLevel?: number): PriceInterface | undefined;

  getDiscountedTotal(priceLevel?: number): PriceInterface | undefined;

  getDiscount(priceLevel?: number): PriceInterface | undefined;

  getDiscountTotal(priceLevel?: number): PriceInterface | undefined;

  getFee(): PriceInterface | undefined;

  getFeeTotal(): PriceInterface | undefined;

  getKickback(): PriceInterface | undefined;

  getKickbackTotal(): PriceInterface | undefined;

  getSurcharge(): PriceInterface | undefined;

  getSurchargeTotal(): PriceInterface | undefined;

  getDifference(priceLevel1: number, priceLevel2: number): PriceInterface | undefined;

  getDifferencePercentage(priceLevel1: number, priceLevel2: number): number;

  setQuantity(quantity: number): void;

  incrementQuantity(): void;

  decrementQuantity(): void;

  toSimpleConfiguration(): SimpleConfiguration | undefined;

  toJSON(): CartItemJSONObject;

  onDataChange?: (cartItem: CartItem) => void;
}

export class CartItemImpl implements CartItem {
  private _id?: number;
  private readonly _priceLevel?: number;
  private _discountCode?: DiscountCode | null;
  private _quantity: number;
  private readonly _configuration?: ConfigurationInterface;
  private readonly _product?: ProductData;
  onDataChange?: (cartItem: CartItem) => void;

  constructor({
    id,
    priceLevel,
    quantity,
    product,
    configuration,
    onDataChange,
    discountCode
  }: {
    id?: number;
    priceLevel?: number;
    quantity: number;
    product?: ProductData;
    configuration?: ConfigurationInterface;
    discountCode?: DiscountCode | null;
    onDataChange?: (cartItem: CartItem) => void;
  }) {
    this._id = id;
    this._priceLevel = priceLevel || 0;
    this._quantity = quantity;
    this._product = product;
    this._configuration = configuration;
    this._discountCode = discountCode;
    this.onDataChange = onDataChange;
  }

  get id(): number | undefined {
    return this._id;
  }

  set id(id: number | undefined) {
    this._id = id;
  }

  get priceLevel(): number | undefined {
    return this._priceLevel;
  }

  get canShowPrices(): boolean {
    // If no product or configuration is set, then we can't show prices
    if (!this._product && !this._configuration) {
      return false;
    }

    if (this._product?.enableColumnPricing) {
      return true;
    }

    return this._priceLevel !== undefined && this._priceLevel > 0;
  }

  get quantity(): number {
    return this._quantity;
  }

  get configuration(): ConfigurationInterface | undefined {
    return this._configuration;
  }

  get product(): ProductData | undefined {
    return this._product;
  }

  get discountCode(): DiscountCode | undefined | null {
    if (!this.canShowPrices) {
      return;
    }
    return this._discountCode;
  }

  applyDiscountCode(discountCode: DiscountCode): void {
    this._discountCode = discountCode;
    this.onDataChange?.(this);
  }

  disableDiscountCode(): void {
    this._discountCode = undefined;
    this.onDataChange?.(this);
  }

  getPrice(priceLevel?: number): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const level = priceLevel ? priceLevel : this._priceLevel;
    let priceObject: PriceInterface | undefined = undefined;

    if (this._product) {
      priceObject = this._product?.price?.find((price) => price.level === level);
    }

    if (this.configuration) {
      // Todo implement configuration class
      let price = 0;
      let currency: CurrencyCode | undefined;
      this._configuration?.selections.forEach((selection) => {
        const selectionPrice = selection.product.price?.find((price) => price.level === level);
        if (selectionPrice && selection.product.enableColumnPricing) {
          price += selectionPrice.price;
          currency = selectionPrice.currency;
        }
      });

      if (!currency) {
        return undefined;
      }

      priceObject = {
        level: level,
        price,
        currency: currency
      };
    }

    if (!priceObject || !priceObject.currency || !priceObject.price || !priceObject.level) {
      return undefined;
    }

    return priceObject;
  }

  getDiscountedPrice(priceLevel?: number): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const level = priceLevel ? priceLevel : this._priceLevel;
    const price = this.getPrice(level);
    if (!this.discountCode || !price) return;

    if (this.discountCode.discountPercentage > 0) {
      const discount = this.discountCode.discountManager.getDiscount(price);
      return {
        ...price,
        price: price.price - discount.price
      };
    }

    // If there is no discount percentage, then we are using a partner price
    const partnerPrice = this.getPrice(this.discountCode.priceLevel);
    if (!partnerPrice) return;
    const partnerPriceSurcharge = this.discountCode.discountManager.feeManager.getSurcharge(partnerPrice);

    return {
      ...partnerPrice,
      price: partnerPrice.price + partnerPriceSurcharge.price
    };
  }

  getDiscount(priceLevel?: number): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const level = priceLevel ? priceLevel : this._priceLevel;
    const price = this.getPrice(level);
    if (!this.discountCode || !price) return;

    if (this.discountCode.discountPercentage > 0) {
      return this.discountCode.discountManager.getDiscount(price);
    }

    // If there is no discount percentage, then we are using a partner price
    const partnerPrice = this.getPrice(this.discountCode.priceLevel);
    if (!partnerPrice) return;

    return this.discountCode.discountManager.getDiscount(price, partnerPrice);
  }

  getDiscountTotal(priceLevel?: number): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const level = priceLevel ? priceLevel : this._priceLevel;
    const discount = this.getDiscount(level);
    if (!discount) return;

    return {
      ...discount,
      price: discount.price * this._quantity
    };
  }

  getTotal(priceLevel?: number): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const level = priceLevel ? priceLevel : this._priceLevel;
    const price = this.getPrice(level);
    if (!price) return;

    return {
      ...price,
      price: price.price * this._quantity
    };
  }

  getDiscountedTotal(priceLevel?: number): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const level = priceLevel ? priceLevel : this._priceLevel;
    const price = this.getDiscountedPrice(level);
    if (!price) return;

    return {
      ...price,
      price: price.price * this._quantity
    };
  }

  getFee(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    if (!this.discountCode?.priceLevel) return;
    const price = this.getPrice(this.discountCode.priceLevel);
    if (!price) return;

    return {
      ...price,
      ...this.discountCode?.discountManager.feeManager.getFee(price)
    };
  }

  getFeeTotal(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const fee = this.getFee();
    if (!fee) return;

    return {
      ...fee,
      price: fee.price * this._quantity
    };
  }

  getKickback(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    if (!this.discountCode?.priceLevel) return;
    const price = this.getPrice(this.discountCode.priceLevel);
    if (!price) return;

    return {
      ...price,
      ...this.discountCode?.discountManager.feeManager.getKickback(price)
    };
  }

  getKickbackTotal(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const kickback = this.getKickback();
    if (!kickback) return;

    return {
      ...kickback,
      price: kickback.price * this._quantity
    };
  }

  getSurcharge(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    if (!this.discountCode?.priceLevel) return;
    const price = this.getPrice(this.discountCode.priceLevel);
    if (!price) return;

    return {
      ...price,
      ...this.discountCode?.discountManager.feeManager.getSurcharge(price)
    };
  }

  getSurchargeTotal(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const surcharge = this.getSurcharge();
    if (!surcharge) return;

    return {
      ...surcharge,
      price: surcharge.price * this._quantity
    };
  }

  getDifference(priceLevel1: number, priceLevel2: number): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    // Get the total subtracted by the discount
    const price1 = this.getPrice(priceLevel1);
    if (!price1) return;

    const price2 = this.getPrice(priceLevel2);
    if (!price2) return;

    return {
      ...price2,
      price: price1.price - price2.price
    };
  }

  getDifferencePercentage(priceLevel1: number, priceLevel2: number): number {
    if (!this.canShowPrices) {
      return 0;
    }
    // Rounded to the nearest integer
    const price1 = this.getPrice(priceLevel1);
    if (!price1) return 0;

    const price2 = this.getDifference(priceLevel1, priceLevel2);
    if (!price2) return 0;

    return Math.round((price2.price / price1.price) * 100);
  }

  setQuantity(quantity: number): void {
    // Set quantity to a range of 1 to 10000
    if (quantity < 1) {
      this._quantity = 1;
    } else if (quantity > 10000) {
      this._quantity = 10000;
    } else {
      this._quantity = quantity;
    }

    if (this.onDataChange) {
      this.onDataChange(this);
    }
  }

  incrementQuantity(): void {
    // Increment quantity by 1 until it reaches 10000
    if (this._quantity < 10000) {
      this._quantity++;
    }

    if (this.onDataChange) {
      this.onDataChange(this);
    }
  }

  decrementQuantity(): void {
    // Decrement quantity by 1 until it reaches 1
    if (this._quantity > 1) {
      this._quantity--;
    }

    if (this.onDataChange) {
      this.onDataChange(this);
    }
  }

  toSimpleConfiguration(): SimpleConfiguration | undefined {
    if (!this._configuration) return;

    return {
      name: this._configuration.name,
      locale: this._configuration.locale,
      configLayoutId: this._configuration.configLayoutId,
      configTypeId: this._configuration.configTypeId,
      qualityImage: this._configuration.qualityImage,
      qualityTypeId: this._configuration.qualityTypeId,
      selections: this._configuration.selections.map((selection) => {
        // TODO: create a toJSON method in a configuration class
        const newCartItem = new CartItemImpl({
          quantity: 1,
          product: selection.product,
          priceLevel: this._priceLevel,
          discountCode: this.discountCode
        });

        return {
          stepId: selection.stepId,
          price: newCartItem.getPrice(),
          discountedPrice: newCartItem.getDiscountedPrice(),
          total: newCartItem.getTotal(),
          discountedTotal: newCartItem.getDiscountedTotal(),
          productNumber: selection.product.productNumber,
          elNumber: selection.product.elNumber,
          productName: selection.product.productName,
          productNameShort: selection.product.productNameShort,
          productCategory: selection.product.product_category?.name || "",
          optionId: selection.option?.id,
          productId: selection.product.id,
          enableColumnPricing: selection.product.enableColumnPricing
        };
      })
    };
  }

  toJSON(): CartItemJSONObject {
    const jsonObject: CartItemJSONObject = {
      id: this._id,
      price: this.getPrice(),
      discountedPrice: this.getDiscountedPrice(),
      total: this.getTotal(),
      discountedTotal: this.getDiscountedTotal(),
      priceLevel: this._priceLevel,
      quantity: this._quantity
    };

    if (this._product) {
      jsonObject.product = {
        id: this._product.id,
        productName: this._product.productName,
        productNameShort: this._product.productNameShort,
        productNumber: this._product.productNumber,
        elNumber: this._product.elNumber,
        price: this._product.price
      };
    }

    if (this._configuration) {
      jsonObject.configuration = this.toSimpleConfiguration();
    }

    return jsonObject;
  }
}
