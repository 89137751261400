import { CurrencyCode } from "@utils/Country/countryEnums";
import { PriceInterface } from "@constants/interfaces";
import { CartItemManager } from "@utils/Cart/CartItemManager";
import { DiscountCode, DiscountCodeImpl, DiscountCodeInfo } from "@utils/Discount/DiscountCode";
import { CartItem, CartItemJSONObject } from "@utils/Cart/CartItem";
import { Retailer } from "@utils/Retailer/Retailer";
import { Order } from "@utils/Order/Order";
import { User } from "@utils/User/User";
import { parseCookies, setCookie } from "nookies";

export interface CartJSONObject {
  userPriceLevel: number;
  currency: CurrencyCode;
  total: PriceInterface | undefined;
  totalWithDiscount: PriceInterface | undefined;
  subtotal: PriceInterface | undefined;
  subtotalWithDiscount: PriceInterface | undefined;
  vat: PriceInterface | undefined;
  vatWithDiscount: PriceInterface | undefined;
  shipping: PriceInterface | undefined;
  discount: PriceInterface | undefined;
  discountCode: DiscountCodeInfo | undefined;
  discountPercentage: number;
  fee: PriceInterface | undefined;
  kickback: PriceInterface | undefined;
  cartItems: CartItemJSONObject[];
}

export interface Cart {
  set order(order: Order | undefined);

  get canShowPrices(): boolean;

  get cartItemManager(): CartItemManager;

  get cartItems(): CartItem[];

  get userPriceLevel(): number;

  get currency(): CurrencyCode;

  get total(): PriceInterface | undefined;

  get totalWithDiscount(): PriceInterface | undefined;

  get subtotal(): PriceInterface | undefined;

  get subtotalWithDiscount(): PriceInterface | undefined;

  get vat(): PriceInterface | undefined;

  get vatWithDiscount(): PriceInterface | undefined;

  get shipping(): PriceInterface | undefined;

  get discount(): PriceInterface | undefined;

  get discountCode(): DiscountCode | undefined;

  get discountPercentage(): number;

  get fee(): PriceInterface | undefined;

  get kickback(): PriceInterface | undefined;

  setCurrency(currency: CurrencyCode): void;

  setUserPriceLevel(priceLevel: number): void;

  applyDiscountCode(discountCode: DiscountCode): void;

  disableDiscountCode(user?: User | null): void;

  saveState(): void;

  restoreState(): void;

  toJSON(): CartJSONObject;
}

export class CartImpl implements Cart {
  private readonly _cartItemManager: CartItemManager;
  private _order?: Order;
  private _discountCode?: DiscountCode;
  private _currency: CurrencyCode = CurrencyCode.NOK;
  private _vatPercentage = 25;
  private _userPriceLevel = 0;
  private readonly onDataChange?: () => void;

  constructor(
    cartItemManager: CartItemManager,
    initialData?: {
      userPriceLevel?: number;
      currency?: CurrencyCode;
      vatPercentage?: number;
      retailer?: Retailer;
    },
    onDataChange?: () => void
  ) {
    this._cartItemManager = cartItemManager;
    this._userPriceLevel = initialData?.userPriceLevel || 0;
    this._currency = initialData?.currency || CurrencyCode.NOK;
    this._vatPercentage = initialData?.vatPercentage || 25;
    this.onDataChange = onDataChange;
  }

  set order(order: Order | undefined) {
    this._order = order;
  }

  get canShowPrices(): boolean {
    const hasPriceLevel = this._userPriceLevel > 0;
    const showPrices =
      this._order?.user?.customerProfile?.showPrices || this._discountCode?.retailer?.showPrice || false;

    const hasRetailer =
      !this._order?.retailer || // If retailer is undefined, consider it as true
      (this.discountCode?.retailer?.price_type &&
        this.discountCode.retailer.price_type.priceLevel > 0 &&
        this._discountCode?.retailer?.showPrice) ||
      false; // Provide a default value of false if hasRetailer is undefined

    return hasPriceLevel && showPrices && hasRetailer;
  }

  get cartItemManager(): CartItemManager {
    return this._cartItemManager;
  }

  get cartItems(): CartItem[] {
    return this._cartItemManager.items;
  }

  get userPriceLevel(): number {
    return this._userPriceLevel;
  }

  get currency(): CurrencyCode {
    return this._currency;
  }

  get applyDiscount(): boolean {
    return this._discountCode?.enabled || false;
  }

  get total(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    // total is the price of all items with the user price level plus vat and shipping
    const subtotal = this.subtotal;
    const vat = this.vat;
    const shipping = this.shipping;
    if (!subtotal || !vat || !shipping) {
      return;
    }

    return {
      level: this.userPriceLevel,
      price: subtotal.price + vat.price + shipping.price,
      currency: this.currency
    };
  }

  get totalWithDiscount(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    // total is the price of all items with the user price level plus vat and shipping
    const subtotal = this.subtotalWithDiscount;
    const vat = this.vatWithDiscount;
    const shipping = this.shipping;
    if (!subtotal || !vat || !shipping) {
      return;
    }

    return {
      level: this.userPriceLevel,
      price: subtotal.price + vat.price + shipping.price,
      currency: this.currency
    };
  }

  get subtotal(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    return this.getItemsTotal(this.userPriceLevel);
  }

  get subtotalWithDiscount(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    if (this.applyDiscount) {
      return {
        level: this._discountCode?.priceLevel,
        price: this._cartItemManager.getItemsTotalWithDiscount(this.userPriceLevel),
        currency: this.currency
      };
    }
    return this.getItemsTotal(this.userPriceLevel);
  }

  get vat(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const total = this.subtotal;
    if (!total) {
      return;
    }

    return {
      level: this.userPriceLevel,
      price: total.price * (this._vatPercentage / 100),
      currency: this.currency
    };
  }

  get vatWithDiscount(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    const total = this.subtotalWithDiscount;
    if (!total) {
      return;
    }

    return {
      level: this.userPriceLevel,
      price: total.price * (this._vatPercentage / 100),
      currency: this.currency
    };
  }

  get shipping(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    return {
      level: 1,
      price: 0,
      currency: CurrencyCode.NOK
    };
  }

  get discount(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    return {
      level: this._discountCode?.priceLevel || 0,
      price: this._cartItemManager.getTotalDiscount(this.userPriceLevel),
      currency: this.currency
    };
  }

  get discountCode(): DiscountCode | undefined {
    return this._discountCode;
  }

  get discountPercentage(): number {
    if (!this.discount) {
      return 0;
    }

    const subTotal = this.getItemsTotal(this.userPriceLevel);
    return (this.discount.price / subTotal.price) * 100 || 0;
  }

  get fee(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }

    return {
      level: this._discountCode?.priceLevel || 0,
      price: this.cartItemManager.getTotalFee(),
      currency: this.currency
    };
  }

  get kickback(): PriceInterface | undefined {
    if (!this.canShowPrices) {
      return;
    }
    return {
      level: this._discountCode?.priceLevel || 0,
      price: this.cartItemManager.getTotalKickback(),
      currency: this.currency
    };
  }

  getItemsTotal(priceLevel: number): PriceInterface {
    const total = this._cartItemManager.getItemsTotal(priceLevel);

    return {
      level: priceLevel,
      price: total,
      currency: this.currency
    };
  }

  setCurrency(currency: CurrencyCode) {
    this._currency = currency;
  }

  setVatPercentage(vatPercentage: number) {
    this._vatPercentage = vatPercentage;
  }

  setUserPriceLevel(priceLevel: number): void {
    this._userPriceLevel = priceLevel;
  }

  applyDiscountCode(discountCode: DiscountCode): void {
    this._discountCode = discountCode;
    this._cartItemManager.applyDiscountCode(this._discountCode);

    this.saveState();
    if (this.onDataChange) {
      this.onDataChange();
    }
  }

  disableDiscountCode(user?: User | null): void {
    this._discountCode = undefined;
    this._cartItemManager.disableDiscountCode();

    if (user && user.discount > 0) {
      const newCode = new DiscountCodeImpl();
      newCode.updateFromUser(user);
      this.applyDiscountCode(newCode);
    }

    this.saveState();
    if (this.onDataChange) {
      this.onDataChange();
    }
  }

  saveState(): void {
    const state = {
      discountCode: this._discountCode?.toJSON()
    };

    setCookie(null, "cartState", JSON.stringify(state), {
      secure: process.env.NODE_ENV !== "development",
      maxAge: 24 * 60 * 60,
      path: "/"
    });
  }

  restoreState(): void {
    const cookies = parseCookies();
    if (!cookies.cartState) {
      return;
    }

    const data = JSON.parse(cookies.cartState) as {
      discountCode: DiscountCodeInfo | undefined;
    };

    if (data.discountCode?.code && data.discountCode.code !== "") {
      const discountCode = new DiscountCodeImpl();
      discountCode.updateFromData(data.discountCode);
      this.applyDiscountCode(discountCode);
    }
  }

  toJSON() {
    return {
      userPriceLevel: this.userPriceLevel,
      currency: this.currency,
      total: this.total,
      totalWithDiscount: this.totalWithDiscount,
      subtotal: this.subtotal,
      subtotalWithDiscount: this.subtotalWithDiscount,
      vat: this.vat,
      vatWithDiscount: this.vatWithDiscount,
      shipping: this.shipping,
      discount: this.discount,
      discountCode: this._discountCode?.toJSON(),
      discountPercentage: this.discountPercentage,
      fee: this.fee,
      kickback: this.kickback,
      cartItems: this._cartItemManager.toJSON()
    };
  }
}
