import { Address, AddressType } from "@utils/Address/Address";
import { CountryName } from "@utils/Country/countryEnums";
import * as Sentry from "@sentry/nextjs";
import { isServer } from "@utils/utils";

export interface AddressData {
  id?: number;
  name: string;
  contactPerson: string;
  address: string;
  phoneNumber: string;
  email: string;
  zip: string;
  city: string;
  country: CountryName;
  company: string;
  type: AddressType;
  default: boolean;
}

export interface AddressRepository {
  create(address: Address): Promise<AddressData | undefined>;

  get(addressId: number): Promise<AddressData | undefined>;

  getMany(): Promise<AddressData[] | undefined>;

  update(address: Address): Promise<AddressData | undefined>;

  delete(addressId: number): Promise<boolean>;
}

export class AddressRepositoryImpl implements AddressRepository {
  _jwt: string | undefined;

  constructor(jwt?: string) {
    this._jwt = jwt;
  }

  async create(address: Address): Promise<AddressData | undefined> {
    try {
      const response = await fetch(`/api/address`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...address.toJSON()
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }

  async get(addressId: number): Promise<AddressData | undefined> {
    try {
      const response = await fetch(`/api/address/${addressId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }

  async getMany(): Promise<AddressData[] | undefined> {
    if (!isServer()) {
      throw new Error("AddressRepositoryImpl.getMany() must be called server-side");
    }

    try {
      const response = await fetch(`${process.env.STRAPI_API_URL}/api/addresses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this._jwt}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return;
    }
  }

  async update(address: Address): Promise<AddressData | undefined> {
    try {
      const response = await fetch(`/api/address/${address.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(address.toJSON())
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }

  async delete(addressId: number): Promise<boolean> {
    try {
      const response = await fetch(`/api/address/${addressId}`, {
        method: "DELETE"
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return true;
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  }
}
