import { Country } from "@utils/Country/Country";
import { CountryName } from "@utils/Country/countryEnums";
import { CountryManager } from "@utils/Country/CountryManager";
import { AddressData, AddressRepository } from "@utils/Address/AddressRepository";
import { User } from "@utils/User/User";

export interface AddressJSONObject {
  id?: number;
  user?: number;
  name?: string;
  contactPerson?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
  zip?: string;
  city?: string;
  country?: CountryName;
  company?: string;
  type?: AddressType;
  default?: boolean;
}

export enum AddressType {
  invoice = "Invoice",
  delivery = "Delivery"
}

export interface Address {
  id?: number;
  user?: User;
  name?: string;
  contactPerson?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
  zip?: string;
  city?: string;
  country?: Country;
  company?: string;
  type?: AddressType | null;
  default?: boolean | null;
  onDataChange?: () => void;

  updateFromData(data: AddressData): void;

  save(): Promise<Address | null>;

  delete(): Promise<boolean>;

  toJSON(): AddressJSONObject;
}

export interface AddressProps {
  id?: number;
  user?: User;
  name?: string;
  contactPerson?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
  zip?: string;
  city?: string;
  country?: CountryName;
  countryManager: CountryManager;
  company?: string;
  type?: AddressType;
  default?: boolean;
  onDataChange?: () => void;
  addressRepository: AddressRepository;
}

export class AddressImpl implements Address {
  _countryManager: CountryManager;
  _addressRepository: AddressRepository;
  _user?: User;
  _id?: number;
  _name?: string;
  _contactPerson?: string;
  _address?: string;
  _phoneNumber?: string;
  _email?: string;
  _zip?: string;
  _city?: string;
  _country?: Country;
  _company?: string;
  _type: AddressType;
  _default: boolean;
  onDataChange?: () => void;

  constructor({
    id,
    user,
    name,
    contactPerson,
    address,
    phoneNumber,
    email,
    zip,
    city,
    country,
    countryManager,
    company,
    type,
    default: isDefault,
    onDataChange,
    addressRepository
  }: AddressProps) {
    this._id = id;
    this._user = user;
    this._name = name;
    this._contactPerson = contactPerson;
    this._address = address;
    this._phoneNumber = phoneNumber;
    this._email = email;
    this._zip = zip;
    this._city = city;
    this._countryManager = countryManager;
    this._country = this._countryManager.getCountryByName(country || CountryName.Norway);
    this._company = company;
    this._type = type || AddressType.invoice;
    this._default = isDefault || false;
    this.onDataChange = onDataChange;
    this._addressRepository = addressRepository;
  }

  get id(): number | undefined {
    return this._id;
  }

  set id(id: number | undefined) {
    this._id = id;
    this.onDataChange?.();
  }

  get user(): User | undefined {
    return this._user;
  }

  set user(user: User | undefined) {
    this._user = user;
    this.onDataChange?.();
  }

  get name(): string | undefined {
    return this._name;
  }

  set name(name: string | undefined) {
    this._name = name;
    this.onDataChange?.();
  }

  get contactPerson(): string | undefined {
    return this._contactPerson;
  }

  set contactPerson(contactPerson: string | undefined) {
    this._contactPerson = contactPerson;
    this.onDataChange?.();
  }

  get address(): string | undefined {
    return this._address;
  }

  set address(address: string | undefined) {
    this._address = address;
    this.onDataChange?.();
  }

  get phoneNumber(): string | undefined {
    return this._phoneNumber;
  }

  set phoneNumber(phoneNumber: string | undefined) {
    this._phoneNumber = phoneNumber;
    this.onDataChange?.();
  }

  get email(): string | undefined {
    return this._email;
  }

  set email(email: string | undefined) {
    this._email = email;
    this.onDataChange?.();
  }

  get zip(): string | undefined {
    return this._zip;
  }

  set zip(zip: string | undefined) {
    this._zip = zip;
    this.onDataChange?.();
  }

  get city(): string | undefined {
    return this._city;
  }

  set city(city: string | undefined) {
    this._city = city;
    this.onDataChange?.();
  }

  get country(): Country | undefined {
    return this._country;
  }

  set country(country: Country | undefined) {
    this._country = country;
    this.onDataChange?.();

    if (this._country) {
      this._country.onDataChange = this.onDataChange;
    }
  }

  get company(): string | undefined {
    return this._company;
  }

  set company(company: string | undefined) {
    this._company = company;
    this.onDataChange?.();
  }

  get type(): AddressType {
    return this._type;
  }

  set type(type: AddressType) {
    this._type = type;
    this.onDataChange?.();
  }

  get default(): boolean {
    return this._default;
  }

  set default(isDefault: boolean) {
    this._default = isDefault;
    this.onDataChange?.();
  }

  async save(): Promise<Address | null> {
    let addressData: AddressData | undefined;

    if (this.id) {
      addressData = await this._addressRepository.update(this);
    } else {
      addressData = await this._addressRepository.create(this);
    }

    if (addressData) {
      this.id = addressData.id;
    }

    return this;
  }

  async delete(): Promise<boolean> {
    if (this.id) {
      return await this._addressRepository.delete(this.id);
    }

    return false;
  }

  updateFromData = (data: AddressData) => {
    this.id = data.id || this.id;
    this.name = data.name || this.name;
    this.contactPerson = data.contactPerson || this.contactPerson;
    this.address = data.address || this.address;
    this.phoneNumber = data.phoneNumber || this.phoneNumber;
    this.email = data.email || this.email;
    this.zip = data.zip || this.zip;
    this.city = data.city || this.city;
    this.country = this._countryManager.getCountryByName(data.country || CountryName.Norway);
    this.company = data.company || this.company;
    this.type = data.type || this.type;
    this.default = data.default;
  };

  toJSON(): AddressJSONObject {
    return {
      id: this.id,
      user: this.user?.id,
      name: this.name,
      contactPerson: this.contactPerson,
      address: this.address,
      phoneNumber: this.phoneNumber,
      email: this.email,
      zip: this.zip,
      city: this.city,
      country: this.country?.name,
      company: this.company,
      type: this.type,
      default: this.default
    };
  }
}
