import { CartItem, SimpleConfiguration } from "@utils/Cart/CartItem";
import axios from "axios";
import { PriceInterface } from "@constants/interfaces";
import * as Sentry from "@sentry/nextjs";
import { ProductData } from "@utils/Product/interfaces";

export interface OrderItemData {
  id?: number;
  order?: number;
  price?: PriceInterface;
  discountPrice?: PriceInterface;
  quantity: number;
  configuration?: SimpleConfiguration;
  product?: ProductData;
  createdAt?: string;
  updatedAt?: string;
}

export interface CreateOrderItemData {
  order: number;
  quantity: number;
  configuration?: SimpleConfiguration;
  product?: number;
  price?: PriceInterface;
  discountPrice?: PriceInterface;
}

export interface OrderItemRepository {
  create(orderId: number, order: CartItem): Promise<OrderItemData | undefined>;

  get(orderItemId: number): Promise<OrderItemData | undefined>;

  update(order: OrderItemData): Promise<OrderItemData | undefined>;

  delete(orderId: number, orderItemId: number): Promise<boolean>;
}

export class OrderItemRepositoryImpl implements OrderItemRepository {
  async create(orderId: number, cartItem: CartItem): Promise<OrderItemData | undefined> {
    const cartItemData: CreateOrderItemData = {
      order: orderId,
      quantity: cartItem.quantity,
      configuration: cartItem.toSimpleConfiguration(),
      product: cartItem.product?.id,
      price: cartItem.getPrice(),
      discountPrice: cartItem.getDiscountedPrice()
    };

    try {
      const { data } = await axios.post<OrderItemData>(`/api/order/${orderId}/order-item`, cartItemData);
      return data;
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }

  get(orderItemId: number): Promise<OrderItemData | undefined> {
    console.log("get order item", orderItemId);
    throw new Error("Method not implemented.");
  }

  update(order: OrderItemData): Promise<OrderItemData | undefined> {
    console.log("update order item", order);
    throw new Error("Method not implemented.");
  }

  async delete(orderId: number, orderItemId: number): Promise<boolean> {
    const response = await fetch(`/api/order/${orderId}/order-item/${orderItemId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.ok;
  }
}
