import { CartItem, CartItemImpl, CartItemJSONObject } from "@utils/Cart/CartItem";
import { ConfigurationInterface } from "@constants/interfaces";
import { DiscountCode } from "@utils/Discount/DiscountCode";

import { ProductData } from "@utils/Product/interfaces";

export interface CartItemManager {
  get items(): CartItem[];

  get quantity(): number;

  get isEmpty(): boolean;

  applyDiscountCode(discountCode: DiscountCode): void;

  disableDiscountCode(): void;

  getItemsTotal(priceLevel: number): number;

  getItemsTotalWithDiscount(priceLevel: number): number;

  getTotalDiscount(priceLevel: number): number;

  getTotalFee(): number;

  getTotalKickback(): number;

  addItem(item: CartItem): void;

  setItem(item: CartItem): void;

  findItemIndex(item: CartItem): number;

  removeItem(index: number): void;

  removeAll(): void;

  saveState(): void;

  restoreState(): void;

  clearState(): void;

  toJSON(): CartItemJSONObject[];
}

export class CartItemManagerImpl implements CartItemManager {
  private cartItems: CartItem[] = [];
  private readonly onDataChange?: (cartItem?: CartItem) => void;

  constructor(onDataChange?: (cartItem?: CartItem) => void) {
    this.onDataChange = onDataChange;
    this.restoreState();
  }

  public get items(): CartItem[] {
    return this.cartItems;
  }

  public get quantity(): number {
    return this.cartItems.reduce((total, item) => total + item.quantity, 0);
  }

  public get isEmpty(): boolean {
    return this.cartItems.length === 0;
  }

  public applyDiscountCode(discountCode: DiscountCode): void {
    this.cartItems.forEach((item) => item.applyDiscountCode(discountCode));
  }

  public disableDiscountCode(): void {
    this.cartItems.forEach((item) => item.disableDiscountCode());
  }

  public getItemsTotal(priceLevel: number): number {
    return this.cartItems.reduce((total, item) => total + (item.getTotal(priceLevel)?.price || 0), 0);
  }

  public getItemsTotalWithDiscount(priceLevel: number): number {
    return this.cartItems.reduce((total, item) => total + (item.getDiscountedTotal(priceLevel)?.price || 0), 0);
  }

  public getTotalDiscount(priceLevel: number): number {
    return this.cartItems.reduce((total, item) => total + (item.getDiscountTotal(priceLevel)?.price || 0), 0);
  }

  public getTotalFee(): number {
    return this.cartItems.reduce((total, item) => total + (item.getFeeTotal()?.price || 0), 0);
  }

  public getTotalKickback(): number {
    return this.cartItems.reduce((total, item) => total + (item.getKickbackTotal()?.price || 0), 0);
  }

  public addItem(item: CartItem): void {
    // If the item already exists in the cart, update the quantity
    item.onDataChange = this.onDataChange;

    const existingItem = this.findItemIndex(item);
    if (existingItem !== -1) {
      this.cartItems[existingItem].setQuantity(this.cartItems[existingItem].quantity + item.quantity);
    }
    // Otherwise, add the item to the cart
    else {
      this.cartItems.push(item);
    }

    if (this.onDataChange) {
      this.onDataChange(item);
    }
    this.saveState();
  }

  setItem(item: CartItem): void {
    // If the item already exists in the cart, update the quantity
    item.onDataChange = this.onDataChange;

    const existingItem = this.findItemIndex(item);
    if (existingItem !== -1) {
      this.cartItems[existingItem].setQuantity(item.quantity);
    }

    // TODO - Add the item to the cart, make sure we dont add duplicates

    if (this.onDataChange) {
      this.onDataChange(item);
    }
    this.saveState();
  }

  public findItemIndex(item: CartItem): number {
    return this.cartItems.findIndex((cartItem) => {
      if (!cartItem.product || !item.product) {
        return false;
      }

      return cartItem.product?.id === item.product?.id;
    });
  }

  public removeItem(index: number): void {
    this.cartItems.splice(index, 1);
    if (this.onDataChange) {
      this.onDataChange();
    }
    this.saveState();
  }

  public removeAll(): void {
    this.cartItems = [];
    if (this.onDataChange) {
      this.onDataChange();
    }
    this.clearState();
  }

  public saveState(): void {
    const state = this.cartItems.map((cartItem) => {
      return {
        product: cartItem.product,
        quantity: cartItem.quantity,
        configuration: cartItem.configuration,
        priceLevel: cartItem.priceLevel
      };
    });
    localStorage.setItem("cartItems", JSON.stringify(state));
  }

  public restoreState(): void {
    const storedState = localStorage.getItem("cartItems");
    if (storedState) {
      const state = JSON.parse(storedState) as {
        product: ProductData | undefined;
        quantity: number;
        configuration: ConfigurationInterface | undefined;
        priceLevel: number;
      }[];
      this.cartItems = state.map(
        (itemState) =>
          new CartItemImpl({
            product: itemState.product,
            quantity: itemState.quantity,
            configuration: itemState.configuration,
            priceLevel: itemState.priceLevel,
            onDataChange: this.onDataChange
          })
      );
    }
  }

  clearState() {
    localStorage.removeItem("cartItems");
  }

  public toJSON(): CartItemJSONObject[] {
    return this.cartItems.map((cartItem) => cartItem.toJSON());
  }
}
