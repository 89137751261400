import { Address, AddressImpl, AddressProps } from "@utils/Address/Address";
import { CountryManagerImpl } from "@utils/Country/CountryManager";
import { AddressRepositoryImpl } from "@utils/Address/AddressRepository";

export class AddressFactory {
  static createAddress(props?: Omit<AddressProps, "countryManager" | "addressRepository" | "user">): Address {
    const countryManager = new CountryManagerImpl();
    const addressRepository = new AddressRepositoryImpl();

    return new AddressImpl({ ...props, countryManager, addressRepository });
  }
}
