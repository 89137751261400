import { OrderStatus, OrderType } from "@utils/Order/Order";
import { isServer } from "@utils/utils";
import { Address } from "@utils/Address/Address";
import { PaginationInterface, PriceInterface } from "@constants/interfaces";
import { OrderItemData } from "@utils/Order/OrderItemRepository";
import { UserData } from "@utils/User/UserRepository";
import { AddressData } from "@utils/Address/AddressRepository";
import { ResponseStatus } from "@utils/email/EmailHandler";

export interface OrderData {
  id: number;
  quantity: number;
  type: OrderType;
  reference: string;
  company: string;
  message: string;
  user: UserData | true;
  order_items: OrderItemData[];
  status: OrderStatus;
  deliveryAddress?: AddressData;
  invoiceAddress?: AddressData;
  createdAt: string;
  updatedAt: string;
  vat?: PriceInterface;
  subTotal?: PriceInterface;
  total?: PriceInterface;
  discount?: PriceInterface;
  fee?: PriceInterface;
  kickback?: PriceInterface;
  surcharge?: number;
  meta?: PaginationInterface;
}

export interface CreateOrderData {
  id?: number;
  uuid?: string;
  company?: string;
  message?: string;
  reference?: string;
  contactPerson?: string;
  status?: OrderStatus;
  type?: OrderType;
  deliveryAddress?: Address | number;
  invoiceAddress?: Address | number;
  user?: number;
  order_items?: number[];
  vat?: PriceInterface;
  subTotal?: PriceInterface;
  total?: PriceInterface;
  discount?: PriceInterface;
  surcharge?: number;
  fee?: PriceInterface;
  kickback?: PriceInterface;
  retailer?: number;
}

export interface OrderRepository {
  create(order: CreateOrderData): Promise<{
    status: ResponseStatus;
    data: OrderData;
    error?: {
      message: string;
    };
  }>;

  get(orderId: number): Promise<OrderData | undefined>;

  getMany(): Promise<OrderData[] | undefined>;

  update(order: OrderData): Promise<OrderData | undefined>;

  delete(orderId: number): Promise<boolean>;
}

export class OrderRepositoryImpl implements OrderRepository {
  _jwt: string | undefined;

  constructor(jwt?: string) {
    this._jwt = jwt;
  }

  async create(order: CreateOrderData): Promise<{
    status: ResponseStatus;
    data: OrderData;
    error?: {
      message: string;
    };
  }> {
    const response = await fetch(`/api/order`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order)
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return await response.json();
  }

  async get(orderId: number): Promise<OrderData | undefined> {
    const response = await fetch(`/api/order/${orderId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  async getMany(): Promise<OrderData[] | undefined> {
    if (!isServer()) {
      throw new Error("OrderRepositoryImpl.getMany() is only available on the server");
    }

    const response = await fetch(`${process.env.STRAPI_API_URL}/api/orders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this._jwt}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  }

  async update(order: OrderData): Promise<OrderData | undefined> {
    // TODO
    return order;
  }

  async delete(orderId: number): Promise<boolean> {
    const response = await fetch(`/api/order/${orderId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.ok;
  }
}
