"use client";

import { createContext, useContext } from "react";
import { UserContext } from "@components/Auth/UserProvider";
import { CartItem } from "@utils/Cart/CartItem";
import { DiscountCode, DiscountCodeImpl } from "@utils/Discount/DiscountCode";
import { useCartContext } from "@utils/Cart/useCartContext";
import { CurrencyCode, LocaleCode } from "@utils/Country/countryEnums";
import { useCountryContext } from "@utils/Country/useCountryContext";
import { Order } from "@utils/Order/Order";
import { PriceInterface } from "@constants/interfaces";
import { getClientLocale } from "@utils/Localization/getClientLocale";

export interface CartContextInterface {
  order: Order | null;
  cartItems: CartItem[];
  showPrices: boolean;
  cartItemQuantity: number;
  subtotal: PriceInterface | undefined;
  subtotalWithDiscount: PriceInterface | undefined;
  total: PriceInterface | undefined;
  totalWithDiscount: PriceInterface | undefined;
  vat: PriceInterface | undefined;
  vatWithDiscount: PriceInterface | undefined;
  fee: PriceInterface | undefined;
  kickback: PriceInterface | undefined;
  discount: PriceInterface | undefined;
  discountCode: DiscountCode | null;
  invalidDiscount: boolean;
  addToCart: (cartItem: CartItem) => void;
  removeFromCart: (index: number) => void;
  setCartItem: (orderItem: CartItem) => void;
  applyDiscountCode: (discountCode: string) => void;
  removeDiscountCode: () => void;
  emptyCart: () => void;
  switchLocale: (locale: LocaleCode) => void;
  onDataChange: () => void;
  removeRetailer: () => void;
}

export const CartContext = createContext<CartContextInterface>({} as CartContextInterface);

export const CartProvider = ({ children }: { children?: JSX.Element | JSX.Element[] }) => {
  const locale = getClientLocale();
  const { user } = useContext(UserContext);
  const { countryManager } = useCountryContext();
  const country = countryManager.getCountryByLocale(locale);

  const { order, cart, cartItemManager, onDataChange } = useCartContext({
    initialPriceLevel: user?.priceLevel,
    initialCurrency: country?.currency == CurrencyCode.NOK ? CurrencyCode.NOK : CurrencyCode.EUR
  });

  // TODO move some of these to the cart class, these acts like getters
  // Login updates the user data with a name parameter
  const discount = () => {
    if (cart && cart.discountCode && cart.discountCode.enabled) {
      return cart.discount;
    }
  };

  const fee = () => {
    if (cart && cart.discountCode && cart.discountCode.enabled && cartItemManager) {
      return {
        level: cart.discountCode.priceLevel,
        price: cartItemManager.getTotalFee(),
        currency: cart.currency
      };
    }
  };

  const kickback = () => {
    if (cart && cart.discountCode && cart.discountCode.enabled && cartItemManager) {
      return {
        level: cart.discountCode.priceLevel,
        price: cartItemManager.getTotalKickback(),
        currency: cart.currency
      };
    }
  };

  const addToCart = async (cartItemToAdd: CartItem) => {
    if (cart?.discountCode) {
      cartItemToAdd.applyDiscountCode(cart.discountCode);
    }
    cart?.cartItemManager.addItem(cartItemToAdd);
  };

  const removeFromCart = async (index: number) => {
    cart?.cartItemManager.removeItem(index);
  };

  const setCartItem = async (cartItem: CartItem) => {
    cart?.cartItemManager.setItem(cartItem);
  };

  const emptyCart = async () => {
    cart?.cartItemManager.removeAll();
  };

  const applyDiscountCode = async (discountCode: string) => {
    if (!cart || !order) return;

    const newCode = new DiscountCodeImpl(discountCode, () => {
      cart.applyDiscountCode(newCode);
      order.retailer = newCode.retailer;
    });
  };

  const removeRetailer = async () => {
    if (!cart || !order) return;
    if (!order.retailer) return;

    order.retailer = undefined;
  };

  const removeDiscountCode = () => {
    cart?.disableDiscountCode(user);
  };

  const switchLocale = async (locale: LocaleCode) => {
    // TODO - Update cart items with new locale
    console.log("switching locale", locale);
    await emptyCart();
    // if (cartItems && cartItems.length === 0) return;
    //
    // const cookies = parseCookies();
    // let user: UserInterface | undefined = undefined;
    //
    // if (cookies.user) {
    //   user = JSON.parse(cookies.user);
    // }
    //
    // for (const item of cartItems) {
    //   if (item.product) {
    //     const product: ProductInterface[] = await getProducts({
    //       locale: locale,
    //       filters: {
    //         productNumber: {
    //           $eq: item.product.productNumber
    //         }
    //       }
    //     });
    //
    //     await updateOrderItem(item.id, { product: product[0].id });
    //
    //     // console.log("%c Updated product ", "color: yellow", product[0]);
    //   }
    //
    //   if (item.attributes.configuration) {
    //     const configuratorItems: ConfigSelectionInterface[] =
    //       item.attributes.configuration.data?.attributes.config_selections.data;
    //
    //     for (let i = 0; i < configuratorItems?.length; i++) {
    //       const product: ProductInterface[] = await getProducts({
    //         user,
    //         locale: locale,
    //         filters: {
    //           productNumber: {
    //             // @ts-ignore
    //             $eq: configuratorItems[i].attributes.product?.data.attributes.productNumber
    //           }
    //         }
    //       });
    //
    //       await updateConfigSelectionItem(configuratorItems[i].id, {
    //         product: product[0].id
    //       });
    //
    //       // console.log("%c Updated configurator item ", "color: yellow", product[0]);
    //
    //       // TODO: fetch configurator price
    //     }
    //   }
    // }
    //
    // await refreshCart();
  };

  return (
    <CartContext.Provider
      value={{
        showPrices: order?.cart.canShowPrices || false,
        order,
        cartItems: cart?.cartItemManager.items || [],
        cartItemQuantity: cart?.cartItemManager.quantity || 0,
        subtotal: cart?.subtotal,
        subtotalWithDiscount: cart?.subtotalWithDiscount,
        total: cart?.total,
        totalWithDiscount: cart?.totalWithDiscount,
        vat: cart?.vat,
        vatWithDiscount: cart?.vatWithDiscount,
        fee: fee(),
        kickback: kickback(),
        discount: discount(),
        discountCode: cart?.discountCode || null,
        invalidDiscount: !cart?.discountCode?.enabled || false,
        addToCart,
        removeFromCart,
        setCartItem,
        applyDiscountCode,
        removeDiscountCode,
        emptyCart,
        switchLocale,
        onDataChange,
        removeRetailer
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
